.aboutContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-light-tan);
}

.title {
  font-size: 2em;
  color: var(--color-orange);
  text-align: center;
  margin-bottom: 20px;
}

.body {
  color: var(--color-dark-gray);
  text-align: center;
  max-width: 500px;
  padding: 1rem;
  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
}
