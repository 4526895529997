.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25em 0.5em;
  background: linear-gradient(
    270deg,
    var(--color-darker-gray) 0%,
    var(--color-dark-gray) 100%
  );
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 101;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
}

.brandLogo {
  color: var(--color-light-tan);
  font-size: 0.75rem;
  white-space: nowrap;
  font-family: Arial, sans-serif;
}

.brandLogo img {
  max-height: 120px;
  max-width: 250px;
}

.navlinks {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0%);
  margin-right: 2rem;
  margin-top: 1rem;
}

.navlinks.open {
  transform: translateX(25%);

  backdrop-filter: blur(5px);
  padding-top: 100px;
}

.navLink {
  color: var(--color-orange);
  text-decoration: none;
  transition: all 0.3s ease;
  font-size: 1.5rem;
  font-weight: 100;
  text-align: left;
  font-family: "Headliner No. 45", sans-serif;
}

.navLink:hover {
  color: var(--color-light-tan);
  cursor: pointer;
}

.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  margin-right: 1rem;
}

.hamburger div {
  width: 2rem;
  height: 0.1rem;
  background: var(--color-light-tan);
  position: relative;
  transition: all 0.3s ease;
}

.hamburger.open div:nth-child(1) {
  transform: rotate(45deg);
  top: 0.6rem;
}

.hamburger.open div:nth-child(2) {
  opacity: 0;
}

.hamburger.open div:nth-child(3) {
  transform: rotate(-45deg);
  top: -0.8rem;
}

.closeButton {
  display: none;
}

@media (max-width: 768px) {
  .navlinks {
    position: absolute;
    height: 100vh;
    width: 100%;
    gap: 1em;
    padding: 2rem;
    padding-top: 100px;
    top: 0;
    right: 0;
    max-width: 250px;
    background-color: rgba(0, 0, 0, 0.7);
    transform: translateX(110%);
    flex-direction: column;
    margin-top: 0;
  }

  .hamburger {
    display: flex;
  }

  .closeButton {
    display: block;
    align-self: flex-end;
    margin: 0.5em;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--color-light-tan);
    z-index: 103;
    position: fixed;
    top: 5px;
    right: 100px;
  }
}
