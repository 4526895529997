body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .slick-track {
  display: flex !important;
}

.slick-slide {
  height: inherit !important;
}

.slick-slide > div {
  height: 100%;
} */

:root {
  --color-white: #ffffff;
  --color-orange: #f7980c;
  --color-orange-light: #ffae42;
  --color-dark-gray: #474749;
  --color-darker-gray: #2c2c2c;
  --color-light-tan: #eed7c1;
}

@font-face {
  font-family: "Headliner No. 45";
  src: url("./fonts/HeadlinerNo45.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.Toastify__toast-container--bottom-right {
  margin-bottom: 2rem;
}
