.contactCard {
  display: flex;
  flex-direction: column;
  background-color: var(--color-dark-gray);
  background: linear-gradient(
    to bottom right,
    var(--color-darker-gray),
    var(--color-dark-gray)
  );
  color: var(--color-dark-gray);
  margin-top: 30px;
  scroll-snap-align: start;
  width: 100%;
  font-family: "Headliner No. 45", sans-serif;
  @media screen and (max-width: 768px) {
    width: unset;
    justify-content: unset;
  }
}

.contactInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    max-width: 100%;
    height: 70%;
    max-width: 400px;
  }
}

.leftColumn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profilePic {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    width: 80%;
    height: auto;
  }
}

.rightColumn {
  margin-bottom: 40px;
}

.name {
  font-size: 1.5em;
  color: var(--color-orange);
  text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.2);
  text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.2);
  font-family: "Headliner No. 45", sans-serif;
  /* visibility: none; */
}

.phone,
.email {
  color: var(--color-light-tan);
  text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.2);
}

.phone a,
.email a {
  color: inherit;
  text-decoration: none;
}

.title {
  font-size: 3em;
  color: var(--color-light-tan);
  background-color: transparent;
  text-align: center;
  text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.2);
  margin-top: 40px;
  /* visibility: none; */

  @media screen and (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 0;
    font-size: 2em;
  }
}

/* ...existing CSS... */

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* visibility: none; */
  @media screen and (max-width: 768px) {
  }
}

.contactButton {
  color: var(--color-gray);
  background-color: var(--color-orange);
  border-radius: 20px;
  border: none;
  padding: 10px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.contactButton:hover {
  background-color: var(--color-light-tan);
  cursor: pointer;
}

/* .contactButton:hover {
  background-color: darken(var(--color-orange), 10%);
} */

.contactButton img {
  margin-right: 10px;
}

/* .slideLeft {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

.slideRight {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

.slideDown {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
} */

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
