.carouselContainer {
  background: linear-gradient(
    to bottom right,
    var(--color-orange-light),
    var(--color-orange)
  );
  max-width: 100vw;
  height: 20%;
  margin-bottom: 1.7em;
}

@media screen and (max-width: 768px) {
  .carouselContainer {
    display: block;
  }
}

.slide {
  width: 100%;
  min-height: 100%;
  /* max-height: 20%; */
  margin-bottom: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-radius: 10px;
}

@media screen and (max-width: 768px) {
  .slide {
    padding: 0;
    border-radius: 0;
  }
}

.testimonialCard {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to bottom right,
    var(--color-dark-gray),
    var(--color-darker-gray)
  );
  color: var(--color-light-tan);
  border-radius: 10px;
  max-width: 50%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

@media screen and (max-width: 820px) {
  .testimonialCard {
    max-width: 100%;
    border-radius: 0;
    background: transparent;
    box-shadow: none;
  }
}

.authorImage {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: auto;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  visibility: hidden;
}

.text {
  flex-grow: 1;
  margin-top: 20px;
}

.message {
  color: var(--color-orange);
  font-size: 1.5rem;
  font-weight: 200;
  padding-left: 7rem;
  padding-right: 7rem;
  visibility: hidden;
}

@media screen and (max-width: 820px) {
  .message {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-left: 0rem;
    padding-right: 0rem;
    font-size: 1.2rem;
    color: var(--color-dark-gray);
  }
}

.author {
  color: var(--color-white);
  font-size: 1.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  visibility: hidden;
}

.location {
  font-weight: 200;
}

.puffIn1 {
  visibility: visible;
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
}
.puffIn2 {
  visibility: visible;
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation-delay: 0.3s;
}
.puffIn3 {
  visibility: visible;
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation-delay: 0.5s;
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
