.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 50px;
  padding-right: 50px;
  width: 100%;
  overflow: visible;
  position: relative;
}

.backgroundImage {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("https://aurapa-site-resources.s3.us-east-2.amazonaws.com/services-images/seats.jpeg");
  background-size: cover;
  background-position: center;
}

.backgroundImage::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
}

.content {
  background: rgba(0, 0, 0, 0.5);
  max-height: 350px;
  display: block;
  height: 100%;
}

.title {
  color: var(--color-orange);
  /* text-shadow: 4px 3px 0px #fff, 9px 8px 0px rgba(0, 0, 0, 0.15); */
  font-size: 6em;
  margin-bottom: 50px;
  margin-top: 50px;
  transition: color 0.3s ease-in-out, text-shadow 0.3s ease-in-out;
  letter-spacing: 0.1em;
  z-index: 1;
  font-family: "Headliner No. 45", sans-serif;
  visibility: hidden;
}

.title span:hover {
  color: var(--color-orange-light);
  cursor: pointer;
}

.highlight {
  color: white;
  text-shadow: 4px 3px 0px var(--color-orange), 9px 8px 0px rgba(0, 0, 0, 0.15);
  -webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: text-pop-up-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.title .highlight:hover {
  color: var(--color-light-tan);
}

.slideTitle {
  width: 100%;
  display: block;
  font-size: 4.5em;
  font-weight: 500;
  display: none;
  color: white;
  text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.2);
  overflow: visible;
  font-family: "Headliner No. 45", sans-serif;
}

.trackIn {
  -webkit-animation: tracking-in-expand-fwd-top 0.8s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  display: block;
}

.slideText {
  font-size: 2.6em;
  font-weight: 300;
  display: none;
  margin: 1rem;
  color: var(--color-orange);
  overflow: visible;
  text-align: justify;
  font-family: "Headliner No. 45", sans-serif;
}

.puffIn {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  display: flex;
}

.slide {
  width: 100%;
  color: var(--color-orange);
  font-size: 1em;
  display: flex;
  flex-direction: column;
  overflow: visible;
  max-width: 40vw;
  min-height: 450px;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 5em;
  }

  .slide {
    max-width: unset;
  }

  .slideTitle {
    font-size: 3em;
  }

  .slideText {
    font-size: 2.5em;
    word-spacing: 1px;
  }
}

@-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 var(--color-dark-gray), 0 2px 0 var(--color-dark-gray),
      0 3px 0 var(--color-dark-gray), 0 4px 0 var(--color-dark-gray),
      0 5px 0 var(--color-dark-gray), 0 6px 0 var(--color-dark-gray),
      0 7px 0 var(--color-dark-gray), 0 8px 0 var(--color-dark-gray),
      0 9px 0 var(--color-dark-gray), 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-shadow: 0 1px 0 var(--color-dark-gray), 0 2px 0 var(--color-dark-gray),
      0 3px 0 var(--color-dark-gray), 0 4px 0 var(--color-dark-gray),
      0 5px 0 var(--color-dark-gray), 0 6px 0 var(--color-dark-gray),
      0 7px 0 var(--color-dark-gray), 0 8px 0 var(--color-dark-gray),
      0 9px 0 var(--color-dark-gray), 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}

@-webkit-keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-expand-fwd-top {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
    transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.slideLeft {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

.slideRight {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

.slideDown {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
