:root {
  --color-white: #ffffff;
  --color-orange: #f7980c;
  --color-dark-gray: #474749;
  --color-darker-gray: #2c2c2c;
  --color-light-tan: #eed7c1;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-dark-gray);
  background-color: var(--color-light-tan);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 13px;
  border-radius: 10px;
  margin-top: 30px;
  overflow: hidden;
}

.header {
  font-size: 2em;
  margin-bottom: 20px;
  font-family: "Headliner No. 45", sans-serif;
  visibility: hidden;
}

.servicesGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  visibility: hidden;
}

.serviceCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: var(--color-darker-gray);
  border-radius: 10px;
  color: var(--color-white);
  width: 150px;
  height: 200px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
}

.serviceImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.serviceTitle {
  position: relative;
  margin-top: 10px;
  text-align: center;
  z-index: 10;
  padding: 10px;
  font-weight: 300;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.8) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  width: 100%;
  box-sizing: border-box;
}

.learnMoreButton {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: var(--color-orange);
  color: var(--color-white);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  visibility: hidden;
}

.learnMoreButton:hover {
  background-color: var(--color-light-tan);
}

.slideLeft {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

.slideRight {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

.slideUp {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
