.header {
  position: relative;
  min-height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--color-white);
  padding: 20px;
}

.backgroundImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.8));
  opacity: 0.5;
}

.textWrapper {
  /* position: relative; */
  text-align: left;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: calc(100vh - 40px); */
}

.title {
  font-size: 6em;
  font-weight: bold;
  margin: 100px 0 5px 0;
  text-shadow: 4px 3px 0px var(--color-orange), 9px 8px 0px rgba(0, 0, 0, 0.15);
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 2.5em;
    line-height: 1;
  }
}

.subtitle {
  font-size: 5.5em;
  color: var(--color-orange);
  font-weight: bold;
  margin-bottom: 20px;
  text-shadow: 4px 3px 0px #fff, 9px 8px 0px rgba(0, 0, 0, 0.15);
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .subtitle {
    font-size: 2.5em;
    line-height: 1;
  }
}

.bodyText {
  font-size: 2em;
  color: var(--color-light-tan);
  padding: 100px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-top: auto;
  font-weight: 200;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  text-align: justify;
  visibility: none;
}

@media screen and (max-width: 768px) {
  .bodyText {
    font-size: 1.2em;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.7);
    margin: 0;
  }
}

.focusIn1 {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

.focusIn2 {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
  animation-delay: 0.5s;
}

.puffIn {
  -webkit-animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  animation-delay: 0.8s;
  visibility: visible;
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
