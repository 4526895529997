.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(
    270deg,
    var(--color-dark-gray) 0%,
    var(--color-darker-gray) 100%
  );
  padding: 10px 0px;
  z-index: 99999;
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px; /* Adjust thickness of the border */
  background: linear-gradient(
    to bottom right,
    var(--color-orange-light),
    var(--color-orange)
  );
}

.icon {
  color: white;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 1.5em;
  transition: color 0.2s ease-in-out;
}

.icon:hover {
  color: var(--color-orange);
}
