.aboutCoach {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-dark-gray);
  background: linear-gradient(
    to bottom right,
    var(--color-darker-gray),
    var(--color-dark-gray)
  );
  margin-bottom: 1.7em;
}

@media screen and (max-width: 768px) {
  .aboutCoach {
    min-height: auto;
  }
}

.title {
  font-size: 3rem;
  margin: 1.5rem 0;
  margin-bottom: 0;
  font-weight: 100;
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
  font-family: "Headliner No. 45", sans-serif;
  visibility: hidden;
}

.popIn {
  -webkit-animation: tracking-in-expand 0.3s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 2rem;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  padding: 1rem;
  gap: 10px;
}

.coachImage {
  width: 12rem;
  height: 12rem;
  margin-left: 1rem;
  margin-bottom: 20px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  visibility: hidden;
}

.fadeIn {
  -webkit-animation: puff-in-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715)
    both;
  animation: puff-in-center 0.5s cubic-bezier(0.47, 0, 0.745, 0.715) both;
  visibility: visible;
}

.coachName {
  font-size: 12rem;
  font-weight: bold;
  font-family: "Headliner No. 45", sans-serif;
  text-align: start;
  color: var(--color-orange);
  text-shadow: 4px 3px 0px #fff, 9px 8px 0px rgba(0, 0, 0, 0.15);
  visibility: hidden;
}

.slideInLeft {
  -webkit-animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

@media screen and (max-width: 821px) {
  .coachName {
    font-size: 8rem;
    text-align: center;
  }

  .coachImage {
    margin-left: 0;
    width: 10rem;
    height: 10rem;
  }

  .bodyText {
    width: 85%;
    font-size: 1.2rem;
    text-align: justify;
  }

  .header {
    gap: 0px;
  }
}

@media screen and (max-width: 768px) {
  .coachName {
    font-size: 6rem;
    text-align: center;
  }
}

.bodyText {
  color: var(--color-white);
  font-size: 1.8em;
  margin-top: 30px;
  width: 70%;
  line-height: 1.5;
  text-align: justify;
  font-weight: 300;
  text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.2);
  visibility: hidden;
}

.slideInRight {
  -webkit-animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-right 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

@media screen and (max-width: 768px) {
  .bodyText {
    width: 90%;
    font-size: 1.2rem;
    text-align: justify;
  }
}

@-webkit-keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-left {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
