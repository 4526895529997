.carouselContainer {
  width: 100%;
  position: relative;
  background-color: var(--color-light-tan);
  overflow: hidden;
}

.slide {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background-color: var(--color-dark-gray);
  font-family: "Headliner No. 45", sans-serif;
}

.image,
.image2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  object-fit: cover;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: opacity 0ms ease-in-out;
}

.image2 {
  opacity: 0;
}

.fadeOut {
  opacity: 0;
  animation: crossfade-out 500ms;
}

.fadeIn {
  opacity: 1;
  animation: crossfade-in 500ms;
}

@media screen and (max-width: 768px) {
  .image,
  .image2 {
    height: 100vh;
  }
}

.secondaryImg {
  max-width: 20%;
  height: auto;
  margin: auto;
}

@media screen and (max-width: 768px) {
  .secondaryImg {
    max-width: 70%;
  }
}

.text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  color: var(--color-white);
  padding: 30px;
  border-radius: 10px;
  text-shadow: 6px 6px 0px rgba(0, 0, 0, 0.2);
  height: 40%;
}

@media screen and (max-width: 768px) {
  .text {
    bottom: 0;
    left: 0;
    right: 0;
    height: 85%;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.contents {
  max-height: 50%;
}

.title {
  font-size: 6em;
  font-weight: 100;
  font-style: italic;
  color: var(--color-white);
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 20px;
  width: 100%;
  visibility: hidden;
}

.highlight {
  visibility: visible;
  -webkit-animation: tracking-in-contract-bck-bottom 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck-bottom 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.aura {
  visibility: visible;
  color: var(--color-orange);
  -webkit-animation: tracking-in-contract-bck 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}

@media screen and (max-width: 820px) {
  .secondaryImg {
    max-width: 60%;
    height: auto;
    margin: auto;
  }

  .title {
    font-size: 3.5em;
    margin-bottom: 40px;
  }
}

.subtitle {
  font-size: 1.5em;
  color: var(--color-light-tan);
}

@media screen and (max-width: 768px) {
  .subtitle {
    font-size: 1.1em;
  }
}

.slick-prev:before {
  color: #c2e812;
}

.slick-next:before {
  color: #c2e812;
}

.slick-dots li button:before {
  color: #c2e812;
}

.slick-dots li.slick-active button:before {
  color: #745296;
}

@keyframes crossfade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.75;
  }
  100% {
    opacity: 1;
  }
}

@keyframes crossfade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
    transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck-bottom {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px) translateY(300px);
    transform: translateZ(400px) translateY(300px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
    transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
    transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

.slideUp {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  visibility: visible;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
